import { IoIosLogOut, IoMdUnlock } from 'react-icons/io';
import { useAuthentication } from '../../auth/RequireAuthentication.tsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetAll } from '../../store/actions.ts';
import { redirectToLogout } from '../../auth';
import { styled } from 'styled-components';
import { Button } from '../button';
import { ResetPasswordModal } from '../../auth/views/ResetPasswordModal.tsx';

const ProfileOuter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Profile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: ${props => props.theme.colours.accent};
  color: ${props => props.theme.colours.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 80px;
  background-color: ${props => props.theme.colours.backgroundDark};
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  padding: 16px 16px 16px 16px;
`;

const DropdownButton = styled(Button)`
  width: 100%;
  white-space: nowrap;
  display: block;
`;

const DropdownItem = styled.div`
  cursor: default;
  margin-bottom: 16px;
`;

// TODO: Create popover component and use here
export const ProfileMenu = () => {
  const { id: userId, name } = useAuthentication();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event: { target: EventTarget | null }) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getInitials = () => {
    if (name) {
      const names = name.split(' ');
      return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    }
    return '';
  };

  const signOutUser = async () => {
    dispatch(resetAll());
    redirectToLogout();
  };

  return (
    <ProfileOuter>
      {showResetPasswordModal &&
        <ResetPasswordModal userId={userId} onClose={() => setShowResetPasswordModal(false)} />}
      <Profile ref={profileRef} onClick={() => setShowMenu(!showMenu)}>
        {getInitials()}
      </Profile>
      {showMenu && (
        <Dropdown ref={dropdownRef}>
          <DropdownItem>{name}</DropdownItem>
          <DropdownButton
            variant="transparent"
            icon={IoMdUnlock}
            iconLayout="start"
            onClick={() => {
              setShowResetPasswordModal(true);
              setShowMenu(false);
            }}>
            Reset password
          </DropdownButton>
          <DropdownButton variant="transparent" icon={IoIosLogOut} iconLayout="start" onClick={signOutUser}>
            Sign out
          </DropdownButton>
        </Dropdown>
      )}
    </ProfileOuter>
  );
};
