import { styled } from 'styled-components';
import { ChangeEvent, useCallback, useState } from 'react';
import { darken, desaturate, lighten } from 'polished';
import { IconType } from 'react-icons';
import { Icon } from '../Icon.tsx';

type TextInputProps = {
  icon?: IconType;
  initialValue?: string;
  label?: string;
  placeholder?: string;
  onChange: (e: string) => void;
  errorMessage?: string;
  disabled?: boolean;
  type?: 'text' | 'password';
};

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  padding: 0;
  border-radius: 6px;
  border: 1px solid ${props => lighten(0.1, props.theme.colors.highlight)};
  color: ${props => props.theme.typography.colors.subtle};
  background-color: ${props => props.theme.colors.highlight};

  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  height: 1.5rem;
  padding-left: 0.5rem;
`;

const DefaultInput = styled.input`
  flex: 1;
  width: 100%;
  padding: 1em;

  border: none;
  background: transparent;
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.typography.colors.subtle};

  &::placeholder {
    color: ${props => props.theme.typography.colors.subtle};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: ${props => desaturate(0.1, props.theme.colors.highlight)};
    color: ${props => darken(0.15, props.theme.typography.colors.subtle)};

    &::placeholder {
      color: ${props => darken(0.15, props.theme.typography.colors.subtle)};
    }
  }
`;

export const InputLabel = styled.label`
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.typography.colors.primary};
  margin-bottom: 0.25em;
  display: block;
`;

export const ErrorMessage = styled.div`
  font-size: ${props => props.theme.fontSizes.xsm};
  color: ${props => props.theme.colors.error};
`;

export const TextInput = (props: TextInputProps) => {
  const { label, placeholder, icon, errorMessage, onChange, initialValue, disabled } = props;
  const [value, setValue] = useState(initialValue ?? '');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      onChange(e.target.value);
    },
    [setValue, onChange]
  );

  const inputProps = {
    value,
    placeholder,
    onChange: handleChange,
    disabled
  };

  return (
    <InputWrapper>
      {label && <InputLabel htmlFor="input-field">{label}</InputLabel>}
      <InputContainer>
        {icon && (
          <IconContainer>
            <Icon IconType={icon} size={'l'} />
          </IconContainer>
        )}
        <DefaultInput type={props.type || 'text'} {...inputProps} />
      </InputContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputWrapper>
  );
};

export const PasswordInput = (props: TextInputProps) => {
  return <TextInput {...props} type="password" />;
};
