export const constants = {
  regExp: {
    // 2 to 50 alphabetical characters that may contain single quotes, hyphens, periods
    // or spaces as long as they are followed by one or more alphabetical characters
    findName: /^(?=.{1,50}$)[A-Za-z0-9]+(?:['\-.\s][A-Za-z0-9]+)*$/,
    email: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/,
    password: /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  },
  roles: ['admin', 'clinician']
};
