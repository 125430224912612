import { Patient } from '../../../api';
import { Tile } from '../../../components/Tile.tsx';
import { styled } from 'styled-components';

const PersonalDetailsTile = styled(Tile)`
  margin-top: 40px;
`;

const PersonalDetailsItem = styled.p`
  margin: 8px 0;
  font-size: ${props => props.theme.fontSizes.sm};
`;

export const PersonalDetailsContent = ({ patient }: { patient: Patient }) => {
  return (
    <PersonalDetailsTile title={'Personal Details'}>
      <PersonalDetailsItem>Patient identifier: {patient.alias}</PersonalDetailsItem>
      <PersonalDetailsItem>Date created: {patient.createdAt.toLocaleDateString()}</PersonalDetailsItem>
      <PersonalDetailsItem>
        Last activity: {patient.lastActivityDate?.toLocaleDateString() || 'N/A'}
      </PersonalDetailsItem>
    </PersonalDetailsTile>
  );
};
