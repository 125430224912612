import { tms } from './tms';

export type Patient = {
  id: number;
  createdAt: Date;
  alias: string;
  firstName: string | null;
  lastName: string | null;
  lastActivityDate: Date | null;
};

interface AddPatientParams {
  alias: string;
  firstName?: string;
  lastName?: string;
}

interface UpdatePatientParams {
  id: number;
  alias: string;
  firstName?: string;
  lastName?: string;
}

interface GetPatientsParams {
  page: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  searchTerm?: string;
}

interface GetPatientsResult {
  patients: Patient[];
  totalPatients: number;
}

const patient = tms.injectEndpoints({
  endpoints: builder => ({
    addPatient: builder.mutation<Patient, AddPatientParams>({
      query: newPatient => ({
        url: `patients`,
        method: 'POST',
        body: {
          ...newPatient
        }
      }),
      invalidatesTags: ['Patient']
    }),
    updatePatient: builder.mutation<Patient, UpdatePatientParams>({
      query: updatedPatient => ({
        url: `patients/${updatedPatient.id}`,
        method: 'PUT',
        body: {
          ...updatedPatient
        }
      }),
      invalidatesTags: ['Patient']
    }),
    getPatient: builder.query<Patient, string>({
      query: id => ({
        url: `patients/${id}`,
        method: 'GET'
      }),
      transformResponse: (response: Patient) => ({
        ...response,
        createdAt: new Date(response.createdAt),
        lastActivityDate: response.lastActivityDate ? new Date(response.lastActivityDate) : null
      }),
      providesTags: (_result, _error, id) => [{ type: 'Patient', id: id }]
    }),
    deletePatient: builder.mutation<void, string>({
      query: id => ({
        url: `patients/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Patient']
    }),
    getPatients: builder.query<GetPatientsResult, GetPatientsParams>({
      query: ({ page, sortBy, sortOrder, searchTerm }) => ({
        url: `patients`,
        method: 'GET',
        params: {
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchTerm: searchTerm
        }
      }),
      transformResponse: (response: GetPatientsResult) => ({
        ...response,
        patients: response.patients.map(patient => ({
          ...patient,
          createdAt: new Date(patient.createdAt),
          lastActivityDate: patient.lastActivityDate ? new Date(patient.lastActivityDate) : null
        }))
      }),
      providesTags: ['Patient']
    })
  }),
  overrideExisting: false
});

export const { useMutation: useAddPatientMutation } = patient.endpoints.addPatient;
export const { useMutation: useUpdatePatientMutation } = patient.endpoints.updatePatient;
export const { useMutation: useDeletePatientMutation } = patient.endpoints.deletePatient;
export const { useQuery: useGetPatientQuery } = patient.endpoints.getPatient;
export const { useQuery: useGetPatientsQuery } = patient.endpoints.getPatients;
