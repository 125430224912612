import { Outlet, useNavigationType } from 'react-router-dom';
import { createContext, useContext, useEffect } from 'react';
import { Profile, useGetProfileQuery } from '../api';

export const AuthenticatedUserContext = createContext<Profile>(null!);

// eslint-disable-next-line react-refresh/only-export-components
export const useAuthentication = () => {
  return useContext(AuthenticatedUserContext);
};

export const RequireAuthentication = () => {
  const navigationType = useNavigationType();
  const { data, isLoading, isFetching, error, isSuccess, refetch } = useGetProfileQuery();

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      refetch();
    }
  }, [navigationType, refetch]);

  if (isLoading) {
    return;
  }

  if (!isSuccess || error || !data) {
    window.location.replace('/sign-in');
    return;
  }

  return (
    <AuthenticatedUserContext.Provider value={data}>{!isFetching && <Outlet />}</AuthenticatedUserContext.Provider>
  );
};
